.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  flex: 1;
  width: 100%;

  & > * {
    flex: 1;
  }
}

.container {
  display: flex;
  align-items: center;
  min-height: 50px;
  position: relative;
  width: 310px;
  margin-block: 1rem;
  flex: 1;
  border-radius: 5px;
  background-color: #000000;

  @mixin mobile {
    width: 100%;
  }
}

.button {
  width: 100%;
  background: transparent;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }
}

.dummy {
  position: absolute;
  inset: 0;
  margin: 0;
}

.errorMessage {
  color: var(--betterplace-color-fg-danger);
}

.loadingButtonWrapper {
  height: 100%;

  /* Apple Pay button had a small section in the left side sticking out, this will cover that as well */
  width: 101%;
  left: -2px;
  position: absolute;
  z-index: 99;

  /* this allows the background to be opaque, but not the loading icon */

  /* 57% over the black button results #919191 which is --betterplace-color-bg-disabled */
  background-color: rgb(255 255 255 / 57%);

  & > button {
    pointer-events: none;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  & svg {
    position: absolute;
    left: 1rem;
    fill: var(--betterplace-color-fg-light-primary);
  }
}

.readyButtonContent {
  display: flex;
  align-items: center;
  gap: var(--betterplace-spacing-300);
}
